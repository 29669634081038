/*--------------------------------------------------*\
                      Firebase 
\*--------------------------------------------------*/

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyAwMSsUW56sS2GlCAOnGnmQ3cjVr_ETD5w",
    authDomain: "site-gaelle-5915e.firebaseapp.com",
    databaseURL: "https://site-gaelle-5915e.firebaseio.com",
    projectId: "site-gaelle-5915e",
    storageBucket: "site-gaelle-5915e.appspot.com",
    messagingSenderId: "592508011051",
    appId: "1:592508011051:web:83dce3b2ad6c79a9f3e06e"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

/*--------------------------------------------------*\
                    Contact Form
\*--------------------------------------------------*/

// Reference messages collection
let ref = firebase.database().ref('Messages');

// contact form
document.getElementById('contactForm').addEventListener('submit', (e) => {
    e.preventDefault();

    // const nom = document.getElementById('name').value;
    // const prenom = document.getElementById('firstName').value;
    // const email = document.getElementById('email').value;
    // const message = document.getElementById('message').value;
    // const submitBtn = document.querySelector('.contact__form__submit');

    // let newMessage = {
    //   email,
    //   message,
    //   nom,
    //   prenom
    // }

    // ref.push(newMessage);

    // submitBtn.innerHTML = "ENVOYER \u2713";
    // submitBtn.style.backgroundColor = "#9CC270";

    // setTimeout(() => {
    //   submitBtn.innerHTML = "ENVOYER";
    //   submitBtn.style.backgroundColor = "#5B5075";
    // },3000);

    // document.getElementById('contactForm').reset();

})

/*--------------------------------------------------*\
                        Nav menu
\*--------------------------------------------------*/

const menuBtn = document.querySelector('.header__menu__btn');
const menuBlock = document.querySelector('.header__menu__block');
const rectHeader = document.querySelector('.accueil__illustration__rect');

let menuOpen = false;

// show menu nav
menuBtn.addEventListener('click', () => {
    if (!menuOpen) {
        menuBtn.classList.add('active');
        menuBlock.classList.add('active');
        rectHeader.classList.add('active');
        menuOpen = true;
    } else {
        menuBtn.classList.remove('active');
        menuBlock.classList.remove('active');
        rectHeader.classList.remove('active');
        menuOpen = false;
    }
})

// hide menu nav if scrolling
document.addEventListener('scroll', () => {
    if (menuOpen === true && scrollY > 0) {
        menuBtn.classList.remove('active');
        menuBlock.classList.remove('active');
        rectHeader.classList.remove('active');
        menuOpen = false;
    }
})

// animation links nav
document.querySelectorAll('.nav__link').forEach(item => {
    item.addEventListener('mouseover', () => {
        item.style.animation = "enter 0.3s both";
    })
    item.addEventListener('mouseout', () => {
        item.style.animation = "leave 0.3s forwards";
    })
})

/*--------------------------------------------------*\
                    portfolio map 
\*--------------------------------------------------*/
const showMap = document.querySelector('.showMap');

// show map fullscreen
document.querySelectorAll('.portfolio__banner__column__img').forEach(item => {
    item.addEventListener('click', () => {
        let map = item.children[0].src;

        showMap.classList.add('is-active');
        document.querySelector('.showMap__box__img').src = map;
    })
})

// hide map fullscreen
showMap.addEventListener('click', () => {
    showMap.classList.remove('is-active');
})

/*--------------------------------------------------*\
                  illustrations attributions
\*--------------------------------------------------*/
const showAttributions = document.querySelector('.showAttributions');

// show illustration
document.querySelector('.contact__attributions').addEventListener('click', () => {
    showAttributions.classList.add('is-active');
})


// hide illustration
showAttributions.addEventListener('click', () => {
    showAttributions.classList.remove('is-active');
})

/*--------------------------------------------------*\
                  scrolltrigger
\*--------------------------------------------------*/

gsap.registerPlugin(ScrollTrigger);

function init() {
    if (window.matchMedia("(min-width: 770px)").matches) {

        // anim title section 
        let titleSections = gsap.utils.toArray('.section__title');

        titleSections.forEach((titleSections) => {
            gsap.from(titleSections, {
                yPercent: -300,
                autoAlpha: 0,
                scrollTrigger: {
                    trigger: titleSections,
                    start: 'bottom 65%',
                    end: 'bottom 10%',
                    scrub: true
                }
            });
        })

        // anim section methode
        gsap.from(".methode__box__card", {
            yPercent: 100,
            scrollTrigger: {
                trigger: ".methode",
                start: "top bottom",
                end: "top 15%",
                scrub: true
            },
        })

        // anim section prestations
        gsap.from(".animCard", {
            yPercent: 100,
            scrollTrigger: {
                trigger: ".prestations",
                start: "top bottom",
                end: "top 15%",
                scrub: 1
            }
        })

        // anim section about
        gsap.from(".about__cube-center__description", {
            autoAlpha: 0,
            scrollTrigger: {
                trigger: ".about",
                start: "top 10%",
                end: "top top",
                scrub: 1
            }
        })

        // anim section portfolio
        gsap.from(".portfolio__banner__column__img", {
            yPercent: 100,
            scrollTrigger: {
                trigger: ".portfolio__banner__column__img",
                start: "top bottom",
                end: "top 75%",
                scrub: 1
            }
        })

        // anim section contact
        gsap.from(".contact__hero", {
            autoAlpha: 0,
            scrollTrigger: {
                trigger: ".contact",
                start: "top center",
                end: "top 25%",
                scrub: 1
            }
        })

    } else {
        return
    }
}

window.addEventListener('load', () => {
    init();
})